import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Assessment from "../shared/assessment/Assessment"

const query = graphql`
  {
    allContentfulNoonePdf(sort: { fields: orderDesc, order: DESC }) {
      edges {
        node {
          pdfFile {
            file {
              url
            }
          }
          year
          title
        }
      }
    }
  }
`

const AssessmentPage = () => {
  const data = useStaticQuery(query)
  const items = data.allContentfulNoonePdf.edges.map(item => {
    const { url } = item.node.pdfFile.file
    const { title } = item.node
    if (url.slice(0, 2) === "//") {
      return {
        title,
        path: `https:${url}`,
      }
    }
    if (url.slice(0, 4) === "http") {
      return {
        title,
        path: url,
      }
    }
  })
  return (
    <div className="mt-12 md:mt-24">
      <Assessment items={items} target="noone" />
    </div>
  )
}

export default AssessmentPage
